import React, { useState, useRef, useEffect } from "react";
import placeholder from "./assets/placeholder.jpg";
import { FaChevronDown } from "react-icons/fa";

function FamilyAccordion({
  products,
  selectedProduct,
  onProductChange,
  selectedFamily,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [previewImages, setPreviewImages] = useState({});
  const [familyProducts, setFamilyProducts] = useState([]);
  const [displayProductName, setDisplayProductName] = useState(
    selectedProduct.name || "Seleziona modello"
  );

  const initialFamily = selectedProduct.family || "";
  const currentFamilyProducts =
    selectedFamily === ""
      ? products
      : selectedFamily || initialFamily
      ? products.filter(
          (product) => product.family === (selectedFamily || initialFamily)
        )
      : [];

  const handleProductChange = (product) => {
    // Only allow selection if it's not the currently selected product
    if (product.code !== selectedProduct.code) {
      onProductChange(product.code);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    setFamilyProducts(currentFamilyProducts);
    // Update display name with first product in new family
    if (currentFamilyProducts.length > 0) {
      const firstProduct = currentFamilyProducts[0];
      setDisplayProductName(
        firstProduct.name +
          (firstProduct.id ? ` - cod. ${firstProduct.id}` : "")
      );
    } else {
      setDisplayProductName("Seleziona modello");
    }
  }, [products, selectedFamily, initialFamily]);

  useEffect(() => {
    const fetchImage = async (product) => {
      const productUrl = `https://emersya.com/jsShowcase/${product.code}?container=emersyaIframe`;
      const response = await fetch(productUrl);
      const data = await response.text();
      const viewerPreviewImageMatch = data.match(
        /src="(https:\/\/cdn\.emersya\.com\/cl\/[^"]+\/preview\.png\?date=[^"]+)"/
      );
      if (viewerPreviewImageMatch) {
        const previewImageUrl = viewerPreviewImageMatch[1];
        setPreviewImages((prevImages) => ({
          ...prevImages,
          [product.code]: previewImageUrl,
        }));
      }
    };

    familyProducts.forEach((product) => {
      if (!previewImages[product.code]) {
        fetchImage(product);
      }
    });
  }, [familyProducts]);

  return (
    <div className="ay-w-full ay-pt-4 ay-pb-6 ay-border-b ay-border-b-black">
      <div className="ay-group ay-gap-x-4 ay-flex ay-justify-between ay-items-center">
        <h2 className="ay-text-lg ay-font-bold">Modello</h2>
        {selectedProduct.name && (
          <p className="ay-uppercase">
            {selectedProduct.name}
            {selectedProduct.id ? ` - cod. ${selectedProduct.id}` : ""}
          </p>
        )}
      </div>

      <div className="ay-mt-2 ay-relative" ref={dropdownRef}>
        <div
          className="ay-border ay-rounded-lg ay-bg-white ay-border-gray-200 ay-w-full ay-p-4 ay-flex ay-justify-between ay-items-center ay-cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{displayProductName}</span>
          <span
            className={`ay-transition-transform ay-duration-300 ${
              isOpen ? "ay-rotate-180" : ""
            }`}
          >
            <FaChevronDown />
          </span>
        </div>

        {isOpen && (
          <div className="ay-absolute ay-z-10 ay-w-full ay-mt-1 ay-divide-y-2 ay-bg-white ay-border ay-border-gray-200 ay-rounded-lg ay-shadow-lg ay-max-h-[70vh] ay-overflow-auto">
            {familyProducts.map((product) => {
              const isSelected = product.code === selectedProduct.code;
              return (
                <div
                  key={`${product.name}-${product.code}`}
                  className={`ay-p-4 ay-flex ay-items-center ${
                    isSelected
                      ? "ay-bg-gray-100 ay-cursor-not-allowed"
                      : "ay-cursor-pointer ay-hover:bg-gray-50"
                  }`}
                  onClick={() => handleProductChange(product)}
                >
                  {previewImages[product.code] ? (
                    <img
                      src={previewImages[product.code]}
                      alt={product.name}
                      className="ay-w-12 ay-h-12 ay-object-cover ay-mr-4"
                    />
                  ) : (
                    <img
                      src={placeholder}
                      alt={product.name}
                      className="ay-w-12 ay-h-12 ay-object-cover ay-mr-4"
                    />
                  )}
                  <div className="ay-flex ay-flex-col">
                    <span>
                      {product.name}
                      {product.id ? ` - cod. ${product.id}` : ""}
                    </span>
                    {isSelected && (
                      <span className="ay-text-sm ay-text-gray-500">
                        Currently selected
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default FamilyAccordion;
